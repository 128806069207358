import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../layouts'
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'
export default function PermissionDenied() {
  return (
    <Layout>
      <Grid container spacing={1} sx={{ padding: 1 }}>
        <Grid item xs={12}>
          <Card sx={{ height: 'calc(100vh - 65px)' }}>
            <CardContent align="center">
              <Typography sx={{ mt: '35vh' }}>
                このページを閲覧する権限がありません
              </Typography>
              <Button
                onClick={() => {
                  navigate(`/`)
                }}
              >
                ホームに戻る
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  )
}
